import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";
import ContactArea from "../../components/ContactArea";
import { useMediaQuery } from "react-responsive";
import rightBg from "../../assets/img/daCrazy/right_bg_arrow_curved_down.png";
import contactbackground from "../../assets/img/daCrazy/contact_background.png";
const ContactContainer = () => {
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });

  return (
    <>
      <div
        style={{
          background: isMob ? "black" : "",
          backgroundImage: isMob ? "" : `url(${contactbackground})`,
          height: isMob ? "auto" : "700px",
          backgroundSize: "100% 80%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          marginTop: "130px",
        }}
      >
        <Breadcumb title="Contact Us" text="Home" />
      </div>

      <div
        style={{
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
          marginTop: "80px",
        }}
      >
        {!isMob && (
          <>
            <div
              style={{
                position: "absolute",
                right: 50,
                top: 50,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={rightBg} style={{ width: "65%" }} />
            </div>
          </>
        )}
      </div>
      <ContactArea
        ClassItem="contact_us_area section-padding-100-0"
        data={data[0].ContactArea}
      />
      <div style={{ marginTop: "300px" }}></div>
    </>
  );
};

export default ContactContainer;
