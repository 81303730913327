import React, { useEffect, useState } from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useMediaQuery } from "react-responsive";

import photoIcon from "../../assets/img/daCrazy/photos.svg";
import videoIcon from "../../assets/img/daCrazy/video.svg";

import rightBg from "../../assets/img/daCrazy/right_bg_arrow_down.png";
import leftBg1 from "../../assets/img/daCrazy/left_bg_two.png";

import eventBg from "../../assets/img/daCrazy/event_bg.png";
import "./style.css";

import photo1 from "../../assets/img/daCrazy/photo1.png";
import photo2 from "../../assets/img/daCrazy/photo2.png";
import photo3 from "../../assets/img/daCrazy/photo3.png";
import photo4 from "../../assets/img/daCrazy/photo4.png";
import photo5 from "../../assets/img/daCrazy/photo5.png";
import photo6 from "../../assets/img/daCrazy/photo6.png";
import photo7 from "../../assets/img/daCrazy/photo7.png";

import video1 from "../../assets/videos/2.mp4";
import video2 from "../../assets/videos/3.mp4";
import video3 from "../../assets/videos/4.mp4";

import { useLocation } from "react-router-dom";

// Replace this with your actual images
const images = [
  { src: photo1, width: 4, height: 3 },
  { src: photo2, width: 4, height: 3 },
  { src: photo3, width: 4, height: 3 },
  { src: photo4, width: 4, height: 3 },
  { src: photo5, width: 4, height: 3 },
  { src: photo6, width: 4, height: 3 },
  { src: photo7, width: 4, height: 3 },
];

// Video data
const videos = [
  { src: video1, type: "video/mp4" },
  { src: video2, type: "video/mp4" },
  { src: video3, type: "video/mp4" },
];

const BeyondSlapContainer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const typeValue = queryParams.get("type");
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const [index, setIndex] = useState(-1);
  const [activeTab, setActiveTab] = useState(0); // 0 = Photos, 1 = Videos

  useEffect(() => {
    if (typeValue === "videos") {
      setActiveTab(1);
    }
  }, [typeValue]);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (event, { photo, index }) => {
    setIndex(index);
  };

  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const IconicTabs = () => {
    const tabs = [
      { icon: photoIcon, text: "Photos" },
      { icon: videoIcon, text: "Videos" },
    ];

    return (
      <div className="iconic-tabs-container">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`iconic-tab ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <img
              src={tab.icon}
              alt={`Tab ${index + 1} Icon`}
              className="tab-icon"
            />
            <p className="tab-text">{tab.text}</p>
            <div className="tab-underline"></div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div style={{ marginTop: "150px" }}>
        <div style={{ backgroundImage: `url(${eventBg})`, padding: "20px" }}>
          <h1 className="main-heading">Beyond Slap</h1>
          <p className="main-paragraph">
            Photo and video collection of the family man, Da Crazy Hawaiian
          </p>
        </div>

        <div style={{ maxWidth: "1280px", margin: "20px auto" }}>
          <IconicTabs />
        </div>

        <div
          style={{ position: "relative", maxWidth: "1980px", margin: "auto" }}
        >
          {!isMob && (
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={rightBg} style={{ width: "50%" }} />
            </div>
          )}

          {/* Render either Photo Gallery or Video Gallery based on activeTab */}
          <div
            style={{
              maxWidth: "1280px",
              width: "100%",
              margin: "auto",
            }}
          >
            {activeTab === 0 ? (
              <>
                <Gallery
                  photos={images}
                  direction="row"
                  onClick={handleClick}
                />
                {!!currentImage && (
                  <Lightbox
                    mainSrc={currentImage.src}
                    nextSrc={nextImage.src}
                    prevSrc={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                  />
                )}
              </>
            ) : (
              <div className="video-gallery">
                {videos.map((video, idx) => (
                  <div key={idx} className="video-container">
                    <video
                      src={video.src}
                      type={video.type}
                      controls
                      className="video-player"
                      style={{ width: "100%" }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            position: "relative",
            maxWidth: "1980px",
            margin: "auto",
          }}
        >
          {!isMob && (
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                display: "flex",
                justifyContent: "flex-start",
                zIndex: "2222",
              }}
            >
              <img src={leftBg1} style={{ width: "35%" }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BeyondSlapContainer;
