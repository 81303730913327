import BeyondSlapContainer from "../containers/BeyondSlap";
import Layout from "../layouts";

const BeyondSlap = () => {
  return (
    <Layout Title="Beyond Slap">
      <BeyondSlapContainer />
    </Layout>
  );
};

export default BeyondSlap;
