import top from "../../../assets/img/daCrazy/daCrazyHeroSalogen.svg";
import bgVideo from "../../../assets/img/daCrazy/video_background.mp4";

const WelcomeArea = () => {
  const handleCoin = () => {
    window.location.href = "/coin";
  };

  return (
    <section
      className="hero-section-home"
      id="home"
      style={{ position: "relative", overflow: "hidden" }}
    >
      {/* Add a video as background */}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          top: 145,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "-1", // Ensures video stays behind the content
        }}
      >
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Hero Content */}
      <div className="app">
        <div className="row align-items-center">
          {/* Welcome Content */}
          <div className="col-12 col-lg-12 col-md-12">
            <div className="text-center" style={{ marginTop: "200px" }}>
              {/* Hero Content */}
              <div className="mb-3 mt-9">
                <img
                  src={top}
                  alt="Rectangular Image"
                  className="mx-auto "
                  style={{ maxWidth: "400px", width: "100%" }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn more-btn white-btn mb-5 "
                  style={{ marginLeft: "-35px" }}
                  onClick={handleCoin}
                >{`Purchase token`}</button>
              </div>
              {/* Cards Section */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeArea;
