import { useEffect, useRef, useState } from "react";
import { addRemoveClassBody } from "../../utils";

import AboutOurCollection from "../../components/AboutOurCollection";
import DiscoverOurNewCollection from "../../components/DiscoverOurNewCollection";
import HowItWorks from "../../components/HowItWorks";
import OurBrands from "../../components/OurBrands";
import OurRoadmap from "../../components/OurRoadmap";
import FAQ from "../../components/FAQ";
import data from "../../data/static.json";
import WelcomeArea from "./WelcomeArea";
import TeamArea from "../../components/TeamArea";
import Subscribe from "../../components/Subscribe";
import ContactArea from "../../components/ContactArea";
import FindSatoshi from "../../components/FindSatoshi";
import UnveilingSatoshi from "../../components/Unveiling";
import twitter from "../../assets/img/icons/X white.svg";
import instagram from "../../assets/img/daCrazy/insta_icon.png";
import facebook from "../../assets/img/daCrazy/fb_icon.png";
import { Link, useHistory } from "react-router-dom";
import HeroHeader from "../../components/FBI/HeroHeader";
import Modal from "../../components/Modal";
import wib_text from "../../assets/img/wib_peoople_text.svg";
import wib_img from "../../assets/img/wib_people.png";
import { useMediaQuery } from "react-responsive";
import rightBg from "../../assets/img/daCrazy/right_bg_1.png";
import leftBg from "../../assets/img/daCrazy/left_bg_1.png";
import daCrazyNewComponent from "../../assets/img/daCrazy/daCrazyHomeSection.svg";
import videoThumbnail from "../../assets/img/daCrazy/thumbnail.png"; // Add the path to your thumbnail image
import playButton from "../../assets/img/daCrazy/play button.png"; // Add the path to your play button image
import videoFile from "../../assets/video/video.mp4"; // Add the path to your video file
import DaCrazyPoster from "../../assets/img/daCrazy/daCrazyPoster.png";
const HomeDemo1Container = () => {
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const navigate = useHistory();
  const [show, setShow] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  return (
    <>
      <WelcomeArea data={data[0].HomeDemo1.WelcomeArea} />

      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row text-center d-flex justify-content-center">
          <div className="col-12 col-lg-4 col-md-6 ">
            <div className="text-center">
              {/* Copywrite */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
                className=" social-img"
                data-wow-delay="0.4s"
              >
                Follow us at :
                <a
                  href="https://x.com/dacrazyhawaiian?lang=en"
                  style={{ marginLeft: "10px" }}
                  target="_blank"
                >
                  <img
                    src={twitter}
                    alt="Twitter"
                    style={{ filter: "invert(1)", height: "35px" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/da_crazy_hawaiian/?hl=en"
                  style={{ marginLeft: "10px" }}
                  target="_blank"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
                <a
                  href="https://www.facebook.com/DACRAZYHAWAIIANSLAPCHAMP/"
                  style={{ marginLeft: "10px" }}
                  target="_blank"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New component */}
      <div
        style={{
          position: "relative",
          maxWidth: "1200px",
          width: "100%",
          height: "700px",
          margin: "100px auto",
          display: isMob && "flex",
          justifyContent: isMob && "center",
        }}
      >
        {!isMob && (
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <img
              src={daCrazyNewComponent}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        )}

        <div
          style={{
            position: "absolute",
            width: "290px",
            height: "500px",
            top: !isMob && "380px",
            right: !isMob && "30%",
          }}
        >
          {!isVideoPlaying && (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={handleVideoClick}
            >
              <img
                src={videoThumbnail}
                alt="Video Thumbnail"
                style={{ width: "100%", height: "100%" }}
              />
              <img
                src={playButton}
                alt="Play Button"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100px",
                  height: "100px",
                }}
              />
            </div>
          )}
          <video
            ref={videoRef}
            src={videoFile}
            style={{
              width: "100%",
              height: "100%",
              display: isVideoPlaying ? "block" : "none",
            }}
            controls
          />
          <Link
            to={"/beyond-slap?type=videos"}
            className="btn"
            style={{ marginTop: "10px" }}
          >
            See More
          </Link>
        </div>
      </div>
      <div style={{ marginTop: "300px" }}></div>
      {/* DA Crazy Poster */}
      <div style={{ maxWidth: "1280px", margin: "50px auto", width: "100%" }}>
        <img src={DaCrazyPoster} alt="" width={"100%"} />
      </div>
      <HeroHeader />

      <div
        style={{
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        {!isMob && (
          <>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,

                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={rightBg} style={{ width: "80%" }} />
            </div>
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 1200,

                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <img src={leftBg} style={{ width: "80%" }} />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 2800,

                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <img src={rightBg2} style={{ width: "60%" }} /> */}
            </div>
          </>
        )}
        <OurRoadmap data={data[0].OurRoadmap} />

        <div style={{ marginTop: "200px" }}></div>
      </div>

      <Subscribe data={data[0].Subscribe} />
      <div style={{ marginTop: "250px" }}></div>

      <ContactArea data={data[0].ContactArea} />
      <div style={{ marginTop: "250px" }}></div>

      <Modal show={show} onClose={() => setShow(false)} />
    </>
  );
};

export default HomeDemo1Container;
