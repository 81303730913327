import logo from "../../assets/img/daCrazy/daCrazyImg.svg";
const Breadcumb = ({ title, text, addOtherTitle }) => {
  return (
    <div className="breadcumb-area clearfix auto-init">
      <div className="breadcumb-content">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div
                aria-label="breadcrumb"
                className="breadcumb--con text-center"
              >
                <img src={logo} alt="" style={{ height: "100px" }} />
                <ol
                  className="breadcrumb justify-content-center wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  {addOtherTitle ? (
                    <li className="breadcrumb-item">
                      <a href="#">{addOtherTitle}</a>
                    </li>
                  ) : (
                    <li className="breadcrumb-item active" aria-current="page">
                      {title}
                    </li>
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcumb;
