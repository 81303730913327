import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, useHistory } from "react-router-dom";
import Aos from "aos";

import Home from "./pages/Home";
import HomeDemo1 from "./pages/HomeDemo1";
import Ordinal from "./pages/Ordinal";
import NFT from "./pages/NFT";
import Coin from "./pages/Coin";
import HomeDemo3 from "./pages/HomeDemo3";
import HomeDemo4 from "./pages/HomeDemo4";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Collection from "./pages/Collection";
import FAQ from "./pages/FAQ";
import Roadmap from "./pages/Roadmap";
import FanPage from "./pages/FanPage";

import "aos/dist/aos.css";
import "./assets/css/style.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";

import "bootstrap/dist/js/bootstrap.bundle.min";
import AiEngine from "./pages/AiEngine";
import FBI from "./pages/FBI";
import Modal from "./components/Modal";
import axios from "axios";
import EventPage from "./pages/Event";
import BeyondSlap from "./pages/BeyondSlap";
const App = () => {
  const history = useHistory();

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });

    const checkForToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const signInToken = urlParams.get("signInToken");

      if (signInToken) {
        localStorage.setItem("signInToken", signInToken);

        // Redirect to the root path
        history.replace("/");
      }
    };

    checkForToken();
  }, [history]);

  // Function to decode the JWT token
  const decodeToken = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  return (
    <div className="App">
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>NFT Marketplace</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          integrity="sha256-eZrrJcwDc/3uDhsdt61sL2oOBY362qM3lon1gyExkL0="
          crossorigin="anonymous"
        />
      </Helmet>
      <Switch>
        {/* <Route path="/" exact component={Home} /> */}
        <Route exact path="/" component={HomeDemo1} />
        <Route path="/ordinals" component={Ordinal} />
        <Route path="/beyond-slap" component={BeyondSlap} />
        {/* <Route path="/win-power-slap-tickets" component={EventPage} /> */}
        <Route path="/ordinals" component={Ordinal} />
        <Route path="/nft" component={NFT} />
        <Route path="/ai-engine" component={AiEngine} />
        <Route path="/fbi" component={FBI} />
        <Route path="/coin" component={Coin} />
        <Route path="/HomeDemo3" component={HomeDemo3} />
        <Route path="/HomeDemo4" component={HomeDemo4} />
        <Route path="/about-us" component={About} />
        <Route path="/faq" component={FAQ} />
        <Route path="/collection" component={Collection} />
        <Route path="/contact-us" component={Contact} />
        <Route path="/roadmap" component={Roadmap} />
        <Route path="/fanpage" component={FanPage} />
      </Switch>
    </div>
  );
};

export default App;
