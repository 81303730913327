import data from "../../data/static.json";
import Breadcumb from "../../components/Breadcumb";
import faqBackground from "../../assets/img/daCrazy/frequently_background.png";
import FAQ from "../../components/FAQ";
import HowItWorks from "../../components/HowItWorks";
import { useMediaQuery } from "react-responsive";
import leftBg from "../../assets/img/daCrazy/left_bg_arrow_curved_down.png";
import rightBg from "../../assets/img/daCrazy/right_bg_1.png";
const FAQContainer = () => {
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });

  return (
    <>
      <div
        style={{
          background: isMob ? "black" : "",
          backgroundImage: isMob ? "none" : `url(${faqBackground})`,
          height: isMob ? "auto" : "700px",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          marginTop: "130px",
        }}
      >
        <Breadcumb
          title="Our FAQ"
          addOtherTitle="Frequently Asked Questions"
          text="Home"
        />
      </div>

      {/* <div style={{ marginTop: "-500px" }}></div> */}
      <FAQ data={data[0].FAQ} />
      {/* <div style={{ marginTop: "-500px" }}></div> */}
      <div
        style={{
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        {!isMob && (
          <>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 750,

                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={rightBg} style={{ width: "65%" }} />
            </div>
            <div
              style={{
                position: "absolute",
                left: -50,

                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <img src={leftBg} style={{ width: "70%" }} />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 2800,

                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <img src={rightBg2} style={{ width: "60%" }} /> */}
            </div>
          </>
        )}
      </div>
      <HowItWorks
        ClassItem="how-steps section-padding-0-40"
        data={data[0].HowItWorks}
      />
      <div style={{ marginTop: "250px" }}></div>
    </>
  );
};

export default FAQContainer;
